<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="openCreateFormDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="purple--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No forms yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <FormListItem :attr="item" :link="!selectOne"></FormListItem>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="purple" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="purple--text">
                            <Avatar :attr="mainProfile" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-form', params: { accountId, formId: item.id } }">
                            <!-- <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon> -->
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateForm">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createFormForm" ref="createFormFormRef" @submit="validateCreateForm" onSubmit="return false;" @keyup.enter.native="validateCreateForm">
                    <div v-if="add">
                        <v-select :items="addFormChoices" v-model="addFormId" label="Select an form"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newFormName
                            label="Display Name"
                            :rules="newFormNameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="The name for the form"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="purple white--text" @click="addForm" :disabled="!createFormForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="purple white--text" @click="createForm" :disabled="!createFormForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateFormDialog v-model="createFormDialogVisible" @cancel="createFormDialogVisible = false" @created="createFormDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import FormListItem from '@/components/list-item/FormListItem.vue';
// import CreateFormDialog from '@/components/account-dashboard/CreateFormDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        FormListItem,
        // CreateFormDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a form"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createFormDialogVisible: false,
        // create form
        displayCreateForm: false,
        createFormForm: null,
        newFormName: null,
        newFormNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Form name is required',
        ],
        // add form
        addFormChoices: [],
        addFormId: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            if (this.selectOne) {
                return 'Select an form';
            }
            switch (this.list.length) {
            case 0:
                return 'No forms';
            case 1:
                return '1 form';
            default:
                return `${this.list.length} forms`;
            }
        },
    },

    watch: {
        displayCreateForm(value) {
            if (value && (this.add || this.create)) {
                this.initAddFormChoices();
            }
        },
    },

    methods: {
        async loadFormList() {
            try {
                this.$store.commit('loading', { loadFormList: true });
                const match = {};
                if (typeof this.domain === 'string') {
                    match.domain = this.domain;
                }
                const response = await this.$client.account(this.$route.params.accountId).form.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadFormList failed', err);
            } finally {
                this.$store.commit('loading', { loadFormList: false });
            }
        },
        onClickItem(formId) {
            if (this.selectOne) {
                this.$emit('selected', { formId });
            }
        },
        openCreateFormDialog() {
            // this.createFormDialogVisible = true;
            this.$router.push({ name: 'account-create-form', params: { accountId: this.$route.params.accountId }, query: { domain: this.$route.params.domain } });
        },
        // async createForm() {
        //     this.error = false;
        //     console.log('createForm');
        //     const request = {
        //         // name: this.name,
        //         name: this.newFormName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if form arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the form to an appropriate location after form is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createForm response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const form = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', form);
        //         this.$emit('added-site', form);
        //         this.displayCreateForm = false;
        //         this.newFormName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateForm() {
        //     if (this.$refs.createFormFormRef.validate()) {
        //         this.createForm();
        //     }
        // },

        // async addForm() {
        //     const form = { id: this.addFormId, label: this.addFormChoices.find((item) => item.value === this.addFormId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', form);
        //     this.displayCreateForm = false;
        //     this.addFormId = null;
        // },
        // async initAddFormChoices() {
        //     // create a map of which form ids are already in the list
        //     const formMap = {};
        //     this.list.forEach((item) => {
        //         formMap[item.id] = true;
        //     });
        //     // when we show the add/create form dialog, load the list of available forms to add to the policy (list of all forms less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`FormViewList.vue fetch forms result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove forms already in the policy, then convert the available forms to a choice list for the v-select [ { text, value }, ... ]
        //         this.addFormChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadFormList();
    },
};
</script>
