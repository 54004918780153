<template>
    <div>
        <template v-if="link">
            <span><router-link :to="{ name: 'account-edit-form', params: { accountId: this.$route.params.accountId, formId: id } }">{{ label }}</router-link> ({{ type }})</span>
        </template>
        <template v-if="!link">
            <span>{{ name }}</span>
        </template>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        // domain() {
        //     return this.attr.domain;
        // },
        // path() {
        //     return this.attr.path;
        // },
        type() {
            switch (this.attr.type) {
            case 'f':
                return 'Form';
            case 'p':
                return 'Poll';
            case 's':
                return 'Survey';
            default:
                return 'Unknown';
            }
        },
        label() {
            return this.attr.label;
        },
    },

};
</script>
